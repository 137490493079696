import React from "react";
import Allergens from "./Allergens";

export default function MenuTitle({ bold, descr, allergens, children }) {
	return (
		<div className="menuTitle">
			{children}
			{bold && (
				<p>
					<span className="bold">#{bold}</span>
					{descr && ` ｜ ${descr}`}.
					{allergens && <Allergens>{allergens}</Allergens>}
				</p>
			)}
		</div>
	);
}
