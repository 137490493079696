// main imports
import * as React from "react";
import { graphql } from "gatsby";

// plugin imports
import { Trans, useI18next, useTranslation } from "gatsby-plugin-react-i18next";

// style imports
import "../../index.scss";
import "../menus.scss";

// svg imports

// components imports
import MenuLayout from "../../../components/Layout/MenuLayout.js";
import Seo from "../../../components/Seo/Seo.js";
import MenuTitle from "../../../components/Menus/MenuTitle";
import Price from "../../../components/Menus/Price";
import Description from "../../../components/Menus/Description";

// page
export default function IndexPage() {
	const { language } = useI18next();
	const { t } = useTranslation("menuCoffee");

	return (
		<MenuLayout>
			<Seo lang={language} title={t("coffee menu")} />
			<div className="bodyContainer menu">
				<section>
					<div>
						<h1>
							<Trans ns="menuCoffee">coffee menu</Trans>.
						</h1>

						<MenuTitle>
							<span className="underline">
								<Trans ns="menuCoffee">
									we have got you covered for take away
								</Trans>
							</span>
						</MenuTitle>

						<MenuTitle bold={t("purists")} />
						<p>
							<Price>2.&nbsp;</Price>
							<Trans ns="menuCoffee">pure black</Trans>.
							<Description>
								<Trans ns="menuCoffee">the espresso</Trans>.
							</Description>
						</p>
						<p>
							<Price>2.9</Price>
							<Trans ns="menuCoffee">power black</Trans>.
							<Description>
								<Trans ns="menuCoffee">the espresso. double</Trans>.
							</Description>
						</p>
						<p>
							<Price>2.4</Price>
							<Trans ns="menuCoffee">espresso macchiato</Trans>.
							<Description>
								<Trans ns="menuCoffee">italian classic. touch of milk</Trans>.
							</Description>
						</p>
						<p>
							<Price>2.4</Price>
							<Trans ns="menuCoffee">americano</Trans>.
							<Description>
								<Trans ns="menuCoffee">a pure black</Trans>.
							</Description>
						</p>
						<p>
							<Price>3.7</Price>
							<Trans ns="menuCoffee">latte</Trans>.
							<Description>
								<Trans ns="menuCoffee">milk. touch of coffee</Trans>.
							</Description>
						</p>
						<p>
							<Price>3.5</Price>
							<Trans ns="menuCoffee">cappuccino</Trans>.
							<Description>
								<Trans ns="menuCoffee">coffee meets foam</Trans>.
							</Description>
						</p>
						<p>
							<Price>3.5</Price>
							<Trans ns="menuCoffee">flat white</Trans>.
							<Description>
								<Trans ns="menuCoffee">our signature latte. coffee</Trans>.
							</Description>
						</p>
						<p>
							<Price>3.8</Price>
							<Trans ns="menuCoffee">iced latte</Trans>.
							<Description>
								<Trans ns="menuCoffee">the purity of coffee. milk. ice</Trans>.
							</Description>
						</p>
						<p>
							<Price>2.7</Price>
							<Trans ns="menuCoffee">iced black</Trans>.
							<Description>
								<Trans ns="menuCoffee">coffee. ice</Trans>.
							</Description>
						</p>

						<MenuTitle bold={t("hot beverages")} />
						<p>
							<Price>5</Price>
							<Trans ns="menuCoffee">brazilian latte</Trans>.
							<Description>
								<Trans ns="menuCoffee">
									condensed milk. espresso shot. milk
								</Trans>
								.
							</Description>
						</p>
						<p>
							<Price>4.4</Price>
							<Trans ns="menuCoffee">flat caramel</Trans>.
							<Description>
								<Trans ns="menuCoffee">coffee. latte. caramel</Trans>.
							</Description>
						</p>
						<p>
							<Price>3.6</Price>
							<Trans ns="menuCoffee">mocha</Trans>.
							<Description>
								<Trans ns="menuCoffee">coffee. chocolate</Trans>.
							</Description>
						</p>
						<p>
							<Price>4.4</Price>
							<Trans ns="menuCoffee">vanilla latte</Trans>.
							<Description>
								<Trans ns="menuCoffee">coffee. latte. vanilla</Trans>.
							</Description>
						</p>
						<p>
							<Price>3.6</Price>
							<Trans ns="menuCoffee">chocolate</Trans>.
							<Description>
								<Trans ns="menuCoffee">latte. chocolate</Trans>.
							</Description>
						</p>
						<p>
							<Price>4.4</Price>
							<Trans ns="menuCoffee">matcha latte</Trans>.
							<Description>
								<Trans ns="menuCoffee">
									matcha. steamed milk to perfection
								</Trans>
								.
							</Description>
						</p>
						<p>
							<Price>4.4</Price>
							<Trans ns="menuCoffee">chai latte</Trans>.
							<Description>
								<Trans ns="menuCoffee">latte. chai</Trans>.
							</Description>
						</p>
						<p>
							<Price>4.4</Price>
							<Trans ns="menuCoffee">red latte</Trans>.
							<Description>
								<Trans ns="menuCoffee">beetroot powder. milk</Trans>.
							</Description>
						</p>
						<p>
							<Price>4.4</Price>
							<Trans ns="menuCoffee">blu latte</Trans>.
							<Description>
								<Trans ns="menuCoffee">espirulina. milk</Trans>.
							</Description>
						</p>
						<p>
							<Price>4.4</Price>
							<Trans ns="menuCoffee">black latte</Trans>.
							<Description>
								<Trans ns="menuCoffee">milk. charcoal</Trans>.
							</Description>
						</p>
						<p>
							<Price>3.6</Price>
							<Trans ns="menuCoffee">loose leaf tea</Trans>.
						</p>

						<MenuTitle bold={t("cold beverages")} />
						<p>
							<Price>4.4</Price>
							<Trans ns="menuCoffee">iced vanilla</Trans>.
							<Description>
								<Trans ns="menuCoffee">
									the perfect combination of coffee. milk. vanilla
								</Trans>
								.
							</Description>
						</p>
						<p>
							<Price>4.4</Price>
							<Trans ns="menuCoffee">salted caramel</Trans>.
							<Description>
								<Trans ns="menuCoffee">cold latte. salted caramel</Trans>.
							</Description>
						</p>
						<p>
							<Price>4.9</Price>
							<Trans ns="menuCoffee">matcha iced latte</Trans>.
							<Description>
								<Trans ns="menuCoffee">matcha. milk. touch of mint</Trans>.
							</Description>
						</p>
						<p>
							<Price>+0.7</Price>
							<Trans ns="menuCoffee">trendy milk</Trans>.
							<Description>
								<Trans ns="menuCoffee">almond / oat / soy</Trans>.
							</Description>
						</p>
					</div>
				</section>
			</div>
		</MenuLayout>
	);
}

// graphql query
export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;
