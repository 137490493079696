// main imports
import * as React from "react";
import { Helmet, Link, Trans, useI18next } from "gatsby-plugin-react-i18next";
import queryString from "query-string";

// plugin imports

// style imports
import "./Layout.scss";
import "/src/styles/main.scss";
import "/src/styles/printMode.scss";

// svg imports
import BossaLogo from "../../svg/logo.svg";

// components imports

// component
export default function MenuLayout({ hideMenu, children }) {
	const { language, originalPath } = useI18next();

	const [printMode, setPrintMode] = React.useState(false);

	React.useEffect(() => {
		const queryParams = queryString.parse(window?.location?.search);
		setPrintMode(queryParams?.print || queryParams?.print === null || false);
	}, []);
	// console.log("Print Mode: " + printMode);

	return (
		<>
			{printMode && (
				<Helmet>
					<html className="printMode" lang={language} />
				</Helmet>
			)}
			<nav>
				<Link to="/menus">
					<div className="navLogo">
						<BossaLogo />
						<div className="logoSubtitle">café boutique.</div>
					</div>
				</Link>

				{!hideMenu && (
					<div className="navMenu bold">
						<div>
							<Link to="/menus">
								<Trans>menus</Trans>
							</Link>
							.
						</div>
					</div>
				)}
			</nav>
			<main>{children}</main>

			<footer>
				<div className="languages">
					{language === "en" && (
						<Link to={originalPath} language={"es"}>
							web en español
						</Link>
					)}
					{language === "es" && (
						<Link to={originalPath} language={"en"}>
							english website
						</Link>
					)}
					.
				</div>

				<span>
					<Link to="/menus">
						<Trans>menus</Trans>
					</Link>
					.
				</span>
				<span>
					<Link to="/#contact">
						<Trans>bookings</Trans>
					</Link>
					.
				</span>

				<span>
					<a href="https://www.instagram.com/bossaestepona/">
						ig @bossaestepona
					</a>
					.
				</span>

				<span>
					<Link to="/legal">
						<Trans>legal</Trans>
					</Link>
					.
				</span>

				<span>
					dev by <a href="https://peim.es">peim! studio</a>.
				</span>
			</footer>
		</>
	);
}
